.typeBlock {
    display: flex;
    margin: 20px;
    border-bottom: 1px solid #eeeeee;
    button {
        margin-left: 20px;
    }
}

.idColumn {
    display: none;
}

.typeEdit {
    margin: 20px;

    .formTypeEdit {
        display: flex;
        width: 100%;
    }
}

.macroEdit {
    margin: 20px;

    .formMacroEdit {
        display: flex;
        width: 100%;
    }
}

.templateEdit {
    margin: 20px;

    .formTemplateEdit {
        width: 100%;
    }
}

.mainSettings {
    padding: 0 15px 15px 15px;
}

.dashboardTable {
    table {
        border: 1px solid #d9d9d9;
        border-radius: 2px;
        border-collapse: collapse;
        tr:not(:last-child), thead {
            border-bottom: 1px solid #f0f0f0;
        }
        thead {
            tr {
            background-color: #f0f0f0;
            }
        }
        td {
            border-bottom: none;
        }
    }
}

.buttonsEditDelete {
    max-width: 150px;
}

.error {
    margin-bottom: 15px;
}