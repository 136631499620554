body {
    margin: 0;    
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #000;
    text-align: left;
    background-color: #fff;
}


h1, h2, h3, h4, h5, h6 {
    color: #000;
}

a {
    color: #4e8fb8;
}

button, input {
    color: black;
}

.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
    background: none;
    border-bottom: #e4e3e3 solid 1px;
}

.ant-menu-inline .ant-menu-item::after {
    border-right: none;
}

.ant-layout-header {
    background: #f0f2f5 !important;
    padding: 0 20px;
}

.ant-layout-footer {
    padding: 12px 50px;
    height: 48px;
    border-top: #e4e3e3 solid 1px;
}
