.header {
    grid-area: h;
    background-color: rgb(255, 255, 255);    
    box-shadow: 0 1px 0 0 #e4e3e3, 0 0 0 1px #e6e1e1;
    z-index: 999;
}

.loginBlock {
    float: right;
    a {
        color: white;
    }
}

.headerDropdown {
    float: right;  
    width: 100%;
}

.headerDropdownAuth {
    float: right;  
    width: 100%;
    padding-right: 0px;
}

.headerRowAuth {
    width: 100%;
    margin: auto;
}

.headerMenu {
    float: right;    
}

.menu {
    width: 200px;
    background-color: rgb(255, 255, 255);
    border-radius: 4px;
    border-bottom: #e4e3e3 solid 1px;
    border-left: #e4e3e3 solid 1px;
}

.menuIcon {
    font-size: 13px !important;
}

.loginRegister {
    float: right;
    display: flex;
}

.comeBack {
    position: absolute;
}

.bellBlock {
    padding: 3px 35px 0 0;
    .bellIcon {
        font-size: 24px;
        color: gray;
    }
}

.rightBlock {
    float: right;
    display: flex;
}

@media only screen and (min-width: 1100px) {
    .headerRow {
        width: 990px;
        margin: auto;
    }
    .comeBackText {
        margin-left: 10px;
    }
    .userName {
        display: contents;
    }
    .headerDropdown {
        padding-right: 45px;
    }
}
@media only screen and (max-width: 1099px) {
    .headerRow {
        width: 100%;
        margin: auto;
    }
    .header {
        padding: 0 24px;
        position: fixed;
        width: 100%;
    }
    .comeBackText {
        font-size: 14px;
        margin-left: 5px;
    }
    .userName {
        display: contents;
    }
    .headerDropdown {
        padding-right: 0px;
    }
}

@media only screen and (max-width: 550px) {
    .userName {
        display: none;
    }
    .header {
        padding: 0 15px;
        position: fixed;
        width: 100%;
    }
}