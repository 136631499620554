.site-layout .site-layout-background {
  background: #fff;
}

.ant-layout-sider {
  background: none;
  border-right: #e4e3e3 solid 1px;
}

.ant-layout-sider-trigger {
  color: black; 
  background: #fff;
  border-top: #e4e3e3 solid 1px;
  border-right: #e4e3e3 solid 1px;
}

.ant-menu {
  background: none;
  border-top: #e4e3e3 solid 1px;
  font-size: 18px;
}

.ant-menu-item .ant-menu-item-icon, .ant-menu-submenu-title .anticon {
  font-size: 18px;
}

.ant-menu-item {
  border-bottom: #e4e3e3 solid 1px;
  margin-bottom: 0 !important;
}

.ant-menu-submenu:not(:last-child) {
  border-bottom: #e4e3e3 solid 1px;
}