.loader {
    margin: auto;
    display: flex;
    height: 100%;

    img {
        width: 40px;
        margin: auto;
    }
}
