.loginForm {
    background: white;
    border-radius: 4px;
    box-shadow: 0 1px 0 0 rgb(228, 227, 227),0 0 0 1px rgb(230, 225, 225);
    padding: 20px 15px;
    margin: auto;

    h1 {
        color: black;
    }
}
