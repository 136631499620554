.idColumn {
    display: none;
}

.dashboardTable {
    width: 100%;
}

.pauseIcon {
    color: #eb2f96;
    font-size: 24px;
    cursor: pointer;
}

.playIcon {
    color: #52c41a;
    font-size: 24px;
    cursor: pointer;    
}

.statusText {
    margin-left: 10px;
}